@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;800;900&display=swap');


/* MMN Rename this file */

.header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
}

.logo-container {
  text-align: right;
  padding-right: 1rem;
}

.handbook-logo {
  width: 5vw;
  min-width: 50px;
}

.title-container {
  flex-grow: 1;
}

.handbook-title {
  text-align: center;
  margin: 0;
  font-size: clamp(12px, 3vw, 64px);
  font-family: 'Inter';
  font-weight: 900;
  font-style: italic ;
  color: #000000;
}

.toc-box {
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-size: .7em;
  font-weight: 200;
}
