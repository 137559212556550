@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

.page-content {
  display: flex;
  justify-content: center;
  max-width: 75%;
  width: 75%;
  height: calc(65vh);
  padding: 2em 3em;
  margin: 1em auto 0;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Inter';
  font-weight: 200;
  font-size: 16px;
  background-color: #faf9f2;
  box-shadow: 5px 5px 15px 3px rgba(100, 100, 111, 0.1);
  border-radius: 10px;
}

.page-sections::-webkit-scrollbar {
  width: .5em;
}

.page-sections::-webkit-scrollbar-thumb {
background-color: #e8e5dc;
outline: 1px solid #c1beb7;
}

.page-sections {
  flex: 1 1 75%;
  padding-right: 20px;
  overflow-y: auto;
}

.page-quick-nav {
  flex: 1 1 25%;
  overflow-y: auto;
  padding-left: 2vw;
}

.page-quick-nav::-webkit-scrollbar {
  width: .5em;
}

.page-quick-nav::-webkit-scrollbar-thumb {
background-color: #e8e5dc;
outline: 1px solid #c1beb7;
}

.quick-nav {
  padding-top: 1em;
  padding-left: 1em;
}

.quick-nav-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.quick-nav-item {
  --custom-font-size: 1.4vw;
}

.video-thumbnail-container {
  display: flex;
  justify-content: center;
}

.video-thumbnail {
  cursor: pointer;
  width: 85%;
  border-radius: 5px;
  transition: transform 0.2s;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.video-container {
  position: relative;
  width: 80%; 
  padding-top: 50%;
  background-color: #252525;
  overflow: hidden;
  
}

.video-container iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 96%; 
  height: 95%; 
  border: 0;
  transform: translate(-50%, -50%);
}

.landing-quote {
  font-family: 'Lora';
  font-style: italic;
  text-align: center;
  font-size: large;
  padding-top:5vh;
  cursor: pointer; 
}

@media (max-width: 768px) {
  .page-content {
    padding: 1.5em 1em;
    margin: 0.5em auto 0;
    max-width: 80%;
    width: 80%;
  }

  .quick-nav {
    padding-top: 1.5em;
    padding-left: .5em;
  }

  .quick-nav-item {
    --custom-font-size: 2.2vw;
  }

  p {
    font-size: 0.875em;
  }
}

.fade-in {
  animation: fadeIn 0.7s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

